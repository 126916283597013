import React, { useState } from 'react';
import AdventureCard from './components/AdventureCard';
import './App.css';  // Import the CSS file

const App = () => {
  const [adventures] = useState([
    {
      title: "Los Santos Crypto Heist",
      description: "Join Franklin, Michael, and Trevor in a high-stakes $cj coin robbery at the Maze Bank. Warning: May involve questionable financial decisions and car chases.",
      image: "shootout.jpg",
      cryptoReward: { symbol: "cj", price: "0.0420" }
    },
    {
      title: "Vinewood Hills Mansion Party",
      description: "Attend the most lavish $cj coin celebration at a Vinewood Hills mansion. BYOG (Bring Your Own Gat)!",
      image: "trio.jpg",
      cryptoReward: { symbol: "cj", price: "0.0420" }
    },
    {
      title: "Sandy Shores $cj Mining Operation",
      description: "Set up a shady $cj mining rig in Trevor's trailer. First one to not get caught by the FIB wins big!",
      image: "lamar.jpg",
      cryptoReward: { symbol: "cj", price: "0.0421" }
    }
  ]);

  return (
    <div className="container">
      <div className="background-animation"></div>
      <div className="content-wrapper">
        <div className="neon-sign">
          <h1>Now I Gotta Giga Ape Coin ($cj)</h1>
        </div>
        <p className="intro-text">
          Welcome to the wildest, most questionable corner of Solana!
        </p>
        <div className="adventure-grid">
          {adventures.map((adventure, index) => (
            <AdventureCard key={index} {...adventure} />
          ))}
        </div>
        <div className="full-width-card">
          <iframe 
            height="400px" 
            width="100%" 
            id="geckoterminal-embed" 
            title="GeckoTerminal Embed" 
            src="https://www.geckoterminal.com/solana/pools/3vwEAch4frXanWHm9b61CjcY5AdnHf9QM9Zb9nu3hcjE?embed=1&info=0&swaps=0" 
            frameBorder="0" 
            allow="clipboard-write" 
            allowFullScreen
          ></iframe>
        </div>
        <div className="disclaimer">
          Disclaimer: This is a cult $cj carries no financial value. Invest at your own risk. 
          We're not responsible for any virtual or real-world consequences of your actions.
        </div>
      </div>
    </div>
  );
};

export default App;