import React from 'react';
import CryptoPrice from './CryptoPrice';

const AdventureCard = ({ title, description, image, cryptoReward }) => (
  <div className="adventure-card">
    <div className="card-content">
      <h2>{title}</h2>
      <img src={image} alt={title} />
      <p>{description}</p>
      <CryptoPrice symbol={cryptoReward.symbol} price={cryptoReward.price} />
      <button className="btn">Join the Heist</button>
    </div>
  </div>
);

export default AdventureCard;